<template>
  <div
    class="chart-container -mt-space2 flex w-full items-center justify-center pb-16 sm-max:flex-col"
  >
    <div
      class="chart relative mb-10 flex items-center justify-center md:-ml-4 md:mb-0 lg:min-h-96 lg:min-w-96 sm-max:h-80 sm-max:w-80"
    >
      <div
        v-if="props.data"
        class="chart__inner-container flex h-full w-full items-center justify-center overflow-visible"
      >
        <Doughnut
          ref="chartInstance"
          :data="chartData"
          :options="chartOptions"
          class="md:min-h-[300px] md:min-w-[300px] lg:max-h-[400px] lg:min-h-[400px] lg:min-w-[400px] lg:max-w-[400px]"
          @mousemove="handleHover"
        />
        <div class="chart__stat absolute">
          <div v-if="selectedStat" class="chart__stat-value">
            <span class="text-4xl font-medium">{{ selectedStat }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="props.data"
      class="data-points flex max-w-md flex-col items-center justify-center md:-mr-4 md:pl-16 lg:pl-28"
    >
      <div
        v-for="(stat, index) in dataPoints"
        :key="index"
        class="flex w-full cursor-pointer items-center justify-center"
        :class="
          index === selectedIndex
            ? `selected border-l-4 bg-white`
            : 'border-b-1'
        "
        :style="{
          borderColor: index === selectedIndex ? stat.color : '#00263E33',
        }"
        @mouseover="selectPoint(index)"
      >
        <p
          class="color-bullet ml-2 mt-1 pr-1/2 text-left text-base"
          :style="bulletStyle(stat)"
        >
          {{ stat.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
  import { Doughnut } from 'vue-chartjs';

  ChartJS.register(ArcElement, Tooltip, Legend);

  const { locale } = useI18n();

  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
  });

  const chartInstance = ref(null);
  const selectedIndex = ref(0);
  const colors = [
    '#77BEFF',
    '#0369EA',
    '#00263E',
    '#26D07C',
    '#004986',
    '#97D700',
  ];
  const dataPoints = ref(
    props.data.map((stat, index) => {
      return {
        label: stat.fields?.statisticName || '',
        value: stat.fields?.statisticPercentage || 0,
        percentageLabel:
          stat.fields?.statisticLabel || stat.fields?.statisticPercentage || 0,
        color: colors[index % colors.length],
      };
    })
  );

  const chartData = computed(() => ({
    labels: dataPoints.value.map((point) => point.label),
    datasets: [
      {
        data: dataPoints.value.map((point) => point.value),
        backgroundColor: dataPoints.value.map((point) => point.color),
        borderColor: dataPoints.value.map((point) => point.color),
      },
    ],
  }));

  const chartOptions = computed(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: false,
      legend: {
        display: false,
      },
    },
    offset: (context) => {
      return selectedIndex.value === context.index ? 30 : 0;
    },
    borderWidth: 0,
    animation: false,
  }));

  const selectedStat = computed(() => {
    if (selectedIndex.value !== null) {
      return locale === 'en-us'
        ? dataPoints.value[selectedIndex.value].value + '%'
        : dataPoints.value[selectedIndex.value].percentageLabel;
    }
    return '';
  });

  const handleHover = (evt) => {
    const points = chartInstance.value.chart.getElementsAtEventForMode(
      evt,
      'nearest',
      { intersect: true },
      true
    );
    if (points.length) {
      selectedIndex.value = points[0].index;
      chartInstance.value.chart.update();
    } else {
      selectedIndex.value = null;
    }
  };

  const selectPoint = (index) => {
    selectedIndex.value = index;
    chartInstance.value.chart.update();
  };

  const bulletStyle = (stat) => {
    return {
      '--bullet-color': stat.color,
    };
  };
</script>

<style scoped lang="scss">
  .color-bullet {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: -27px;
      display: inline-block;
      width: 16px;
      height: 16px;
      background-color: var(--bullet-color);
      border-radius: 50%;
      margin-right: 5px;
    }
  }
</style>
